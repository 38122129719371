import {
  EqualityCheck,
  SimpleDate,
  SimpleDuration,
} from "@idot-digital/calendar-api";

/**
 * Stringifies price in cents to german locale and optionally adds euro sign
 * @param price price in cents (100 = 1€)
 * @param euroSign optional if euro sign should be added, default false
 * @returns string for given price
 */
export function stringifyPrice(
  price: number,
  currencySign: string | undefined
): string {
  // Check if price is finite when using Math min/max
  if (!isFinite(price)) return "";

  price /= 100;
  let string = price.toFixed(2);
  string = string.replace(".", ",");
  return string + (currencySign || "");
}

/**
 * Checks if two dates are in the same calendar week
 * @param date1 SimpleDate 1
 * @param date2 SimpleDate 2
 * @returns if both date are in the same week
 */
export function isInSameWeek(date1: SimpleDate, date2: SimpleDate): boolean {
  return (
    date1.getYear() === date2.getYear() && date1.getWeek() === date2.getWeek()
  );
}
/**
 * Checks if two dates are on the same day
 * @param date1 SimpleDate 1
 * @param date2 SimpleDate 2
 * @returns if both date are on the same day
 */
export function areOnSameDay(date1: SimpleDate, date2?: SimpleDate): boolean {
  if (!date2) date2 = SimpleDate.now();
  return (
    date1.getYear() === date2.getYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDay() === date2.getDay()
  );
}

// cut out a duration from another duration -> can split duration into two or completely remove it or leave it unchanged or shrink it
export function subtractDuration(
  duration: SimpleDuration,
  subtract?: SimpleDuration
): SimpleDuration[] {
  if (!subtract) return [duration];
  const result: SimpleDuration[] = [];
  const start = duration.getBeginning();
  const end = duration.getEnd();
  const subStart = subtract.getBeginning();
  const subEnd = subtract.getEnd();
  if (
    subStart.exportInt() >= end.exportInt() ||
    subEnd.exportInt() <= start.exportInt()
  ) {
    return [duration];
  }
  // start of duration is before start of subtract -> add start to start of sub to result
  if (subStart.exportInt() > start.exportInt()) {
    result.push(new SimpleDuration(start, subStart));
  }
  // end of duration is after end of subtract -> add end of sub to end of duration to result
  if (subEnd.exportInt() > end.exportInt()) {
    result.push(new SimpleDuration(subEnd, end));
  }
  return result;
}

export function getMinuteOfDay(date: SimpleDate): number {
  return date.getHour() * 60 + date.getMinute();
}

export function addDurations(durations: SimpleDuration[]): SimpleDuration[] {
  if (durations.length === 0) return [];
  const result: SimpleDuration[] = [];
  const sortedDurations = durations.sort((a, b) =>
    a.getBeginning().isEqual(b.getBeginning())
  );
  for (const duration of sortedDurations) {
    const last = result[result.length - 1];
    if (
      last &&
      last.getEnd().isEqual(duration.getBeginning()) !== EqualityCheck.earlier
    ) {
      last.end = duration.end;
    } else {
      result.push(duration);
    }
  }
  return result;
}

export function getDaysBetween(date1: SimpleDate, date2: SimpleDate): number {
  return (
    (date1.copy().setHours(0, 0).toDate().getTime() -
      date2.copy().setHours(0, 0).toDate().getTime()) /
    1000 /
    60 /
    60 /
    24
  );
}

export function joinWithSpecialLast(
  array: string[],
  separator: string,
  lastSeparator: string
): string {
  if (array.length === 0) return "";
  if (array.length === 1) return array[0];
  return array.slice(0, -1).join(separator) + lastSeparator + array.slice(-1);
}
