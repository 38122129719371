import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";

import { useAppointmentPopup } from "./AppointmentPopupContext";
import { useServer } from "../../../Server/ServerContext";
import AppointmentServer from "../../../Server/Appointments/AppointmentServer";
import DraggableDialog from "../../../Generic/DraggableDialog";

export interface AppointmentPopupDeleteProps {
  open: boolean;
  onClose: () => void;
  queueDelete: (deleteFunction: (sendMail: boolean) => void) => void;
}

export default function AppointmentPopupDelete(
  props: AppointmentPopupDeleteProps
) {
  const { account } = useServer();

  const [loading, setLoading] = React.useState(false);

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { onClose, appointment } = useAppointmentPopup();

  const remove = () => {
    setLoading(true);
    props.queueDelete(async (sendMail: boolean) => {
      await AppointmentServer.cancel(
        appointment,
        account?.username || "",
        sendMail
      );
      if (mounted.current) {
        onClose?.();
        setLoading(false);
      }
    });
  };

  return (
    <DraggableDialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Stornieren</DialogTitle>
      <DialogContent>
        <Typography>
          Möchten Sie diesen Termin wirklich stornieren? Der Termin wird dann
          unwiederruflich gelöscht.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          variant="contained"
          onClick={props.onClose}
          disabled={loading}
        >
          Abbrechen
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={remove}
          disabled={loading}
        >
          {loading ? <CircularProgress /> : "Stornieren"}
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
}
