import React from "react";
import {
  AppointmentDurations,
  AppointmentTasks,
} from "../../../Server/Appointments/AppointmentTypes";
import { Service, Task } from "../../../Server/Services/ServiceTypes";
import { Percent, RestartAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { stringifyPrice } from "../../../../Functions/functions";
import { Styles } from "../../../../Types";
import NumberInput from "../../../Generic/NumberInput";
import { useServer } from "../../../Server/ServerContext";
import { useAppointmentPopup } from "./AppointmentPopupContext";

export interface AppointmentPricePickerProps {
  main_services: Service[];
  additional_services: Service[];
  tasks: AppointmentTasks[];
  onChange: (tasks: AppointmentTasks[]) => void;
  toggleDiscount: () => void;
  durations: AppointmentDurations[];
}

export const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "85%",
    mt: 0,
  },
  priceDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  priceWrapper: {
    display: "flex",
    alignItems: "center",
  },
  discountWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  serviceWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  taskWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 0.5,
  },
  serviceName: {
    mr: 1,
  },
  input: {
    flex: "0 0 4rem",
  },
} satisfies Styles;

export default function AppointmentPricePicker(
  props: AppointmentPricePickerProps
) {
  const {
    defaultPrice,
    totalPrice,
    price,
    appointment,
    isNew,
    setAppointmentData,
  } = useAppointmentPopup();
  const { settings } = useServer();

  // sort many service with accopanying additional services and price differences
  const services = React.useMemo(() => {
    const services = props.main_services.map((service) => ({
      service,
      tasks: service.tasks
        .filter((t) => props.durations.some((t1) => t1.taskid === t.id))
        .map((task) => ({
          task: task as Task | undefined,
          taskData: props.tasks.find(({ taskid }) => task.id === taskid),
        })),
      additional_services: [] as {
        service: Service;
        tasks: {
          task: Task | undefined;
          taskData: AppointmentTasks | undefined;
        }[];
      }[],
    }));
    props.additional_services.forEach((service) => {
      const mainService = services.find(
        ({ service: { additional_serviceids } }) =>
          additional_serviceids.includes(service.id)
      );
      if (mainService)
        mainService.additional_services.push({
          service,
          tasks: service.tasks
            .filter((t) => props.durations.some((t1) => t1.taskid === t.id))
            .map((task) => ({
              task,
              taskData: props.tasks.find(({ taskid }) => task.id === taskid),
            })),
        });
      else
        services.push({
          service,
          tasks: service.tasks.map((task) => ({
            task,
            taskData: props.tasks.find(({ taskid }) => task.id === taskid),
          })),
          additional_services: [],
        });
    });
    const deletedTasks = props.tasks.filter((t) => t.taskid === null);
    if (deletedTasks.length > 0) {
      services.push({
        service: {
          id: -1,
          name: "Unbekannt",
          additional_serviceids: [],
          tasks: [],
          bookable: false,
          categoryid: -1,
          is_additional_service: false,
          no_additional_service_text: "",
        },
        tasks: deletedTasks.map((task) => ({
          employeeid: undefined,
          taskData: task,
          task: undefined,
        })),
        additional_services: [],
      });
    }

    return services;
  }, [
    props.additional_services,
    props.main_services,
    props.tasks,
    props.durations,
  ]);

  const resetPricePopoverAnchorEl = React.useRef<HTMLButtonElement | null>(
    null
  );
  const [resetPricePopoverOpen, setResetPricePopoverOpen] =
    React.useState(false);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.priceDisplay}>
        <Typography>Grundpreis:</Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <Tooltip title="Grundpreis dieses Termins">
            <Typography>
              {stringifyPrice(
                isNew
                  ? appointment.discountid
                    ? defaultPrice.discounted_price
                    : defaultPrice.default_price
                  : (price ?? 0),
                settings.getKey("currency_sign")
              )}
            </Typography>
          </Tooltip>
          {isNew && (
            <Tooltip title="Standardpreis für diesen Termin">
              {defaultPrice.default_price !== defaultPrice.discounted_price ? (
                <>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mr: -1, ml: 1 }}
                  />
                  <Box sx={styles.priceWrapper}>
                    <Tooltip
                      title={`Rabatt ${appointment.discountid ? "entfernen" : "hinzufügen"}`}
                    >
                      <IconButton
                        onClick={props.toggleDiscount}
                        color={!appointment.discountid ? "default" : "primary"}
                      >
                        <Percent />
                      </IconButton>
                    </Tooltip>
                    <Box sx={styles.discountWrapper}>
                      <Typography variant="caption">
                        {stringifyPrice(
                          defaultPrice.default_price,
                          settings.getKey("currency_sign")
                        )}
                      </Typography>
                      <Typography variant="caption">
                        {stringifyPrice(
                          defaultPrice.discounted_price,
                          settings.getKey("currency_sign")
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : price !== defaultPrice.default_price ? (
                <Typography variant="body1">
                  {"(" +
                    stringifyPrice(
                      defaultPrice.default_price,
                      settings.getKey("currency_sign")
                    ) +
                    ")"}
                </Typography>
              ) : (
                <span></span>
              )}
            </Tooltip>
          )}
          {!isNew && (
            <>
              {appointment.discountid ? (
                <Tooltip title="Preis zurücksetzen (Dieser Termin wurde mit Rabatt gebucht)">
                  <IconButton
                    ref={resetPricePopoverAnchorEl}
                    onClick={() => setResetPricePopoverOpen(true)}
                  >
                    <Percent color="primary" />
                  </IconButton>
                </Tooltip>
              ) : (
                price !== defaultPrice.default_price && (
                  <Tooltip title="Preis zurücksetzen">
                    <IconButton
                      ref={resetPricePopoverAnchorEl}
                      onClick={() => setResetPricePopoverOpen(true)}
                    >
                      <RestartAlt color="action" />
                    </IconButton>
                  </Tooltip>
                )
              )}
              <Popover
                anchorEl={resetPricePopoverAnchorEl.current}
                open={resetPricePopoverOpen}
                onClose={() => setResetPricePopoverOpen(false)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box p={2} maxWidth={600}>
                  <Typography variant="h6">Preis zurücksetzen</Typography>
                  <Typography>
                    Wollen Sie den Preis für diesen Termin zurücksetzen? Hierbei
                    wird ein potentieller Rabatt entfernt und anhand der
                    aktuellen Preise der Leistungen der Preis neu berechnet.
                  </Typography>
                  <Box display="flex" gap={1}>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={() => setResetPricePopoverOpen(false)}
                    >
                      Abbrechen
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setResetPricePopoverOpen(false);
                        // this cues a recalculation of the appointment price
                        setAppointmentData({
                          price: undefined,
                          discountid: null,
                        });
                      }}
                    >
                      Preis zurücksetzen
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </>
          )}
        </Box>
      </Box>
      <Divider
        orientation="horizontal"
        sx={{ width: "100%", borderStyle: "dashed" }}
      >
        <Typography variant="overline">Preisabweichungen</Typography>
      </Divider>
      {services.map(({ service, additional_services, tasks }) => (
        <Box key={"service-" + service.id} mb={0.5}>
          <Box sx={styles.serviceWrapper}>
            <Typography sx={styles.serviceName} fontStyle="italic">
              {service.name}
            </Typography>
            <Box ml={2} width="100%">
              {tasks.map(({ task, taskData }, index) => (
                <Box
                  sx={styles.taskWrapper}
                  key={"task-" + (task ? task.id : -index)}
                >
                  <Typography sx={styles.serviceName}>
                    {task?.name ?? <em>Aufgabe gelöscht</em>}{" "}
                    {taskData
                      ? `(${stringifyPrice(
                          taskData!.task_price,
                          settings.getKey("currency_sign")
                        )})`
                      : null}
                  </Typography>
                  <NumberInput
                    sx={styles.input}
                    value={(taskData?.price_difference ?? 0) / 100 || ""}
                    onChange={(value) => {
                      props.onChange(
                        props.tasks.map((task2) =>
                          (
                            task
                              ? task2.taskid === task.id
                              : task2.taskid === null &&
                                task2.price_difference ===
                                  taskData?.price_difference
                          )
                            ? {
                                ...task2,
                                price_difference: Math.round(
                                  (value as number) * 100
                                ),
                              }
                            : task2
                        )
                      );
                    }}
                    allowNegative
                    decimal
                    endAdornment={<Typography>€</Typography>}
                  />
                </Box>
              ))}
              {tasks.length === 0 && (
                <Typography variant="caption">
                  Keine Aufgaben dieser Leistung in den Zeiten gefunden
                </Typography>
              )}
              {additional_services.map(({ service, tasks }) => (
                <Box key={"service-" + service.id} sx={styles.serviceWrapper}>
                  <Typography sx={styles.serviceName} fontStyle="italic">
                    {service.name}
                  </Typography>
                  <Box
                    ml={2}
                    sx={{
                      width: (theme) => `calc(100% - ${theme.spacing(2)})`,
                    }}
                  >
                    {tasks.map(({ task, taskData }) =>
                      task ? (
                        <Box sx={styles.taskWrapper} key={"task-" + task.id}>
                          <Typography sx={styles.serviceName}>
                            {task.name}
                            {taskData
                              ? `(${stringifyPrice(
                                  taskData!.task_price,
                                  settings.getKey("currency_sign")
                                )})`
                              : null}
                          </Typography>
                          <NumberInput
                            sx={styles.input}
                            value={
                              (taskData?.price_difference ?? 0) / 100 || ""
                            }
                            onChange={(value) => {
                              props.onChange(
                                props.tasks.map((task2) =>
                                  task2.taskid === task.id
                                    ? {
                                        ...task2,
                                        price_difference: Math.round(
                                          (value as number) * 100
                                        ),
                                      }
                                    : task2
                                )
                              );
                            }}
                            allowNegative
                            decimal
                            endAdornment={<Typography>€</Typography>}
                          />
                        </Box>
                      ) : null
                    )}
                    {tasks.length === 0 && (
                      <Typography variant="caption">
                        Keine Aufgaben dieser Leistung in den Zeiten gefunden
                      </Typography>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      ))}
      {services.length === 0 && (
        <Typography variant="caption">Kein Service ausgewählt</Typography>
      )}
      <Divider
        orientation="horizontal"
        sx={{ width: "100%", borderStyle: "dashed" }}
      >
        <Typography variant="overline">Gesamtpreis</Typography>
      </Divider>
      <Box sx={styles.priceDisplay}>
        <Typography>Gesamtpreis:</Typography>
        <Tooltip title="Eingestellter Gesamtpreis dieses Termine">
          <Typography fontWeight="bold">
            {stringifyPrice(totalPrice, settings.getKey("currency_sign"))}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
}
