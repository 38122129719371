import { deepCopy, isEqual } from "../../../Functions/ObjectFunctions";
import { Service, Task } from "./ServiceTypes";
import TaskHelper from "./Tasks/TaskHelper";

const ServiceFunctions = {
  isEqual(service1: Service, service2: Service) {
    return isEqual(service1, service2);
  },

  isValid(service: Service):
    | {
        valid: true;
        error?: undefined;
      }
    | { valid: false; error: string } {
    if (service.tasks.length === 0)
      return { valid: false, error: "Keine Tasks angelegt" };
    for (const task of service.tasks) {
      if (TaskHelper.hasInvalidDurations(task))
        return { valid: false, error: "Task mit unmöglichen Zeiten" };
      if (!task.name) return { valid: false, error: "Task ohne Name" };
      // if (task.allowed_employees.some((e) => !e.price))
      //   return { valid: false, error: "Mitarbeiter ohne Preis" };
      if (isNaN(task.factor)) return { valid: false, error: "Kein Faktor" };
      if (task.preparation_time > 99 || task.follow_up_time > 99)
        return {
          valid: false,
          error: "Vorbereitungs- oder Nachbereitungszeit zu lang",
        };
    }
    if (!service.name) return { valid: false, error: "Fehlender Name" };
    if (service.categoryid <= 0)
      return { valid: false, error: "Keine Kategorie" };
    if (!service.is_additional_service && !doVariationsHaveLength(service))
      return { valid: false, error: "Hauptservice hat keine Zeit" };
    return { valid: true };
  },

  copy(service: Service) {
    return deepCopy(service) as Service;
  },

  copyTasks(tasks: Task[]) {
    return deepCopy(tasks) as Task[];
  },
};

export default ServiceFunctions;

/**
 * Check if every variation of service is longer than 0min
 */
function doVariationsHaveLength(service: Service): boolean {
  const variants = Array.from(
    service.tasks
      .reduce((map, task) => {
        if (!map.has(task.variation_index)) map.set(task.variation_index, []);
        map.get(task.variation_index)?.push(task);
        return map;
      }, new Map<number, Task[]>())
      .values()
  );

  return variants.every((tasks) =>
    tasks.some((task) =>
      task.durations.some(
        (d, i, durations) =>
          durations[i + 1] !== undefined && d < durations[i + 1]
      )
    )
  );
}
