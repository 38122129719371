import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Server from "../Generic/GenericServer";
import {
  DailyStatementData,
  DailyStatementInputData,
} from "./DailyStatementTypes";
import { SimpleDate } from "@idot-digital/calendar-api";
import { queryClient } from "../../../queryClient";
import config from "../../../config";

const DailyStatementServer = {
  async setData(data: DailyStatementInputData): Promise<DailyStatementData> {
    data.date = new Date(data.date);
    data.date.setHours(12, 0, 0, 0);
    const res = await Server.post<DailyStatementData>(
      "/daily_statement/create_daily_statement_data",
      {
        body: { data, date: data.date.toISOString() },
        errorMessage: `Fehler beim Erstellen der Tagesabrechnung für den ${new Date(data.date).toLocaleDateString("de-DE")}`,
      }
    );

    queryClient.invalidateQueries({
      queryKey: ["daily_statement"],
      exact: false,
    });

    res.fin.date = new Date(res.fin.date);
    return res;
  },

  async createPDF(date: Date) {
    const url = `${config.serverRoot}/${config.api_link}/daily_statement/create_pdf?date=${date.toISOString()}&t=${await DailyStatementServer.getURLSecret()}`;
    // setTimeout is needed is safari since it otherwise prevent the window.open
    setTimeout(() => {
      window.open(url, "_blank");
    });

    queryClient.invalidateQueries({
      queryKey: ["daily_statement"],
      exact: false,
    });
  },

  async listData(
    before: Date,
    count: number
  ): Promise<
    {
      id: number;
      statement_date: Date;
      data: DailyStatementData;
      pdf_url: string | null;
      created_at: Date;
    }[]
  > {
    const res = await Server.get<
      {
        id: number;
        statement_date: Date;
        data: DailyStatementData;
        pdf_url: string | null;
        created_at: Date;
      }[]
    >("/daily_statement/list_daily_statements", {
      query: {
        before: before.toISOString(),
        count,
      },
      errorMessage: `Fehler beim Laden der letzten ${count} Tagesabrechnungen seit dem ${before.toLocaleDateString("de-DE")}`,
    });

    res.forEach((data) => {
      data.data.fin.date = new Date(data.data.fin.date);
      data.statement_date = new Date(data.statement_date);
      data.created_at = new Date(data.created_at);
    });

    return res;
  },

  async checkDataIntegrity(): Promise<
    | { valid: true; firstWrongDate?: undefined }
    | { valid: false; firstWrongDate: Date }
  > {
    const res = await Server.get<
      | { valid: true; firstWrongDate?: undefined }
      | { valid: false; firstWrongDate: string }
    >("/daily_statement/check_data_integrity", {
      errorMessage: "Fehler beim Überprüfen der Tagesabrechnungen",
    });

    if (!res.valid) {
      return {
        valid: false,
        firstWrongDate: new Date(res.firstWrongDate),
      };
    }

    return {
      valid: true,
    };
  },

  async getURLSecret() {
    const { url_secret } = await Server.get<{ url_secret: string }>(
      "/daily_statement/url_secret"
    );
    return url_secret;
  },

  async openPDF(pdfURL: string) {
    const token = await DailyStatementServer.getURLSecret();
    // setTimeout is needed is safari since it otherwise prevent the window.open
    setTimeout(() => {
      window.open(pdfURL + "?t=" + token, "_blank");
    });
  },

  useDataOfMonth(date: SimpleDate) {
    const lastOfMonth = date.copy();
    lastOfMonth
      .setDay(1, 23, 59)
      .setMonth(lastOfMonth.getMonth() + 1)
      .add(0, 0, -1);
    return useQuery({
      queryKey: ["daily_statement", "month", lastOfMonth.getDateString()],
      queryFn: () =>
        DailyStatementServer.listData(
          lastOfMonth.toDate(),
          lastOfMonth.getDay()
        ),
    });
  },

  useListData() {
    return useInfiniteQuery({
      queryKey: ["daily_statement", "list"],
      queryFn: ({ pageParam }) => DailyStatementServer.listData(pageParam, 10),
      getNextPageParam: (lastPage) =>
        lastPage?.at(-1)?.statement_date ?? new Date(),
      initialPageParam: new Date(),
      initialData: undefined,
    });
  },
};

export default DailyStatementServer;
