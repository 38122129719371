import React from "react";

import { SimpleDuration } from "@idot-digital/calendar-api";

import { Styles } from "../../../Types";
import {
  AirplaneTicket,
  FreeBreakfastOutlined,
  Hotel,
  Lightbulb,
  WorkOffOutlined,
} from "@mui/icons-material";
import {
  AbsenseType,
  ActualWorkhoursType,
  DayOff,
  WorkhoursType,
} from "../../Server/WorkingHours/WorkhoursTypes";
import { Box } from "@mui/material";
import { Colors } from "@idot-digital/generic-helpers";

export interface AppointmentNodeProps {
  type: ActualWorkhoursType;
  duration: SimpleDuration;
  // only used to detect when the size of the node changes
  height: number;
  altBackground?: boolean;
}

const staticStyles: Styles = {
  wrapper: {
    "--bg": (theme) => Colors.opacify(theme.palette.primary.light, 0.7),
    color: "#fff",
    background: "var(--bg)",
    marginTop: ".5px",
    width: (theme) => `calc(100% - 2 * ${theme.spacing(0.5)})`,
    height: (theme) => `calc(100% - 2 * ${theme.spacing(0.25)} - 1px)`,
    display: "flex",
    flexDirection: "column",
    padding: (theme) => theme.spacing(0.25, 0.5),
    overflow: "hidden",
    borderRadius: 1,
    pointerEvents: "none",
    zIndex: -1,
  },
  absenseWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    width: "100%",
    color: (theme) => theme.palette.primary.contrastText,
  },
};

export default function AbsenseNode(props: AppointmentNodeProps) {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [height, setHeight] = React.useState(0);

  const [ref, setRef] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!ref) return;
    const updateHeight = () => {
      // Height without padding
      const style = window.getComputedStyle(ref, null);
      if (mounted.current)
        setHeight(parseInt(style.getPropertyValue("height")));
    };

    // Prevent update of non appoinment nodes
    // as they not rely on the height
    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, [ref, props.height]);

  const iconCount = React.useMemo(() => {
    return Math.max(Math.floor(height / 200), 1);
  }, [height]);

  const fontSizeProp = React.useMemo(() => {
    if (height < 24) return "inherit";
    if (height < 50) return "small";
    if (height < 100) return "medium";
    return "large";
  }, [height]);

  const getIconOfAbsense = (key: any) => {
    switch (props.type) {
      case DayOff:
      case WorkhoursType.workhours:
        return (
          <WorkOffOutlined fontSize={fontSizeProp} color="inherit" key={key} />
        );
      case WorkhoursType.break:
        return (
          <FreeBreakfastOutlined
            fontSize={fontSizeProp}
            color="inherit"
            key={key}
          />
        );
      case AbsenseType.sick:
        return <Hotel fontSize={fontSizeProp} color="inherit" key={key} />;
      case AbsenseType.vacation:
        return (
          <AirplaneTicket fontSize={fontSizeProp} color="inherit" key={key} />
        );
      case AbsenseType.training:
        return <Lightbulb fontSize={fontSizeProp} color="inherit" key={key} />;
    }
  };

  return (
    <Box
      component="div"
      sx={{
        ...staticStyles.wrapper,
        // set font size as fallback when height to small
        // to prevent icon from being to big
        fontSize: Math.max(height - 4, 4),
        ...(props.altBackground && {
          background:
            "repeating-linear-gradient(45deg, var(--bg) 0px, var(--bg) 50px, transparent 51px, transparent 100px)",
        }),
      }}
      ref={setRef}
    >
      <Box sx={staticStyles.absenseWrapper}>
        {Array.from({ length: iconCount }, (_, i) => getIconOfAbsense(i))}
      </Box>
    </Box>
  );
}
