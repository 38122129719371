import { SimpleDate, SimpleDuration } from "@idot-digital/calendar-api";
import { queryClient } from "../../../queryClient";
import { ID } from "../../../Types";
import Server from "../Generic/GenericServer";
import WorkhoursServer from "../WorkingHours/WorkhoursServer";
import {
  AbsenseType,
  WeekWorkhours,
  WorkhoursType,
} from "../WorkingHours/WorkhoursTypes";
import {
  CreateEmployee,
  Employee,
  EmployeeAbsense,
  ListEmployee,
  ServerListEmployee,
} from "./EmployeeTypes";
import { useQuery } from "@tanstack/react-query";

const EmployeeServer = {
  list: async (
    cursor: string = ""
  ): Promise<{
    data: ListEmployee[];
    more: boolean;
  }> => {
    const res = await Server.get<{
      more: boolean;
      data: ServerListEmployee[];
    }>("/employees", {
      query: {
        cursor,
      },
      errorMessage: "Fehler beim Laden der Mitarbeiter",
    });
    return {
      more: res.more,
      data: res.data.map((employee) => ({
        ...employee,
        shortName: employee.shortname,
        fullName: employee.fullname,
      })),
    };
  },

  listAll: async (): Promise<ListEmployee[]> => {
    let cursor = "";
    let more = true;
    let data: ListEmployee[] = [];

    while (more) {
      const result = await EmployeeServer.list(cursor);
      data = data.concat(result.data);
      more = result.more;
      cursor = data[data.length - 1]?.shortName || cursor;
    }

    return data;
  },

  get: async (id: ID): Promise<Employee> => {
    if (id <= 0) throw new Error("Invalid id");
    const [employee, workhours, breaks, booking_time] = await Promise.all([
      Server.get<ServerListEmployee>("/employees/:id", {
        params: {
          id,
        },
        errorMessage: "Fehler beim Laden des Mitarbeiters mit id " + id,
      }),
      WorkhoursServer.getRegular(id, WorkhoursType.workhours),
      WorkhoursServer.getRegular(id, WorkhoursType.break),
      WorkhoursServer.getRegular(id, WorkhoursType.booking_time),
    ]);
    return {
      ...employee,
      shortName: employee.shortname,
      fullName: employee.fullname,
      workhours: workhours.map((wh) =>
        wh ? new SimpleDuration(wh.start, wh.end) : null
      ),
      breaks: breaks.map((br) =>
        br ? new SimpleDuration(br.start, br.end) : null
      ),
      booking_time: booking_time.map((bt) =>
        bt ? new SimpleDuration(bt.start, bt.end) : null
      ),
    };
  },

  create: async (employee: CreateEmployee): Promise<ID> => {
    const id = parseInt(
      await Server.post<string>("/employees", {
        body: employee,
        errorMessage: "Fehler beim Erstellen des Mitarbeiters",
      })
    );
    await WorkhoursServer.updateRegular(
      id,
      employee.workhours.map((wh) =>
        wh
          ? {
              start: wh.start,
              end: wh.end,
            }
          : null
      ) as WeekWorkhours,
      employee.breaks.map((wh) =>
        wh
          ? {
              start: wh.start,
              end: wh.end,
            }
          : null
      ) as WeekWorkhours,
      employee.booking_time.map((wh) =>
        wh
          ? {
              start: wh.start,
              end: wh.end,
            }
          : null
      ) as WeekWorkhours
    );

    queryClient.invalidateQueries({
      exact: false,
      queryKey: ["employee"],
    });

    return id;
  },

  update: async (employee: Employee, startDate?: SimpleDate): Promise<void> => {
    await Promise.all([
      Server.patch("/employees/:id", {
        body: employee,
        params: {
          id: employee.id,
        },
        errorMessage: "Fehler beim Speichern des Mitarbeiters",
      }),
      WorkhoursServer.updateRegular(
        employee.id,
        employee.workhours.map((wh) =>
          wh
            ? {
                start: wh.start,
                end: wh.end,
              }
            : null
        ) as WeekWorkhours,
        employee.breaks.map((wh) =>
          wh
            ? {
                start: wh.start,
                end: wh.end,
              }
            : null
        ) as WeekWorkhours,
        employee.booking_time.map((wh) =>
          wh
            ? {
                start: wh.start,
                end: wh.end,
              }
            : null
        ) as WeekWorkhours,
        startDate
      ),
    ]);

    queryClient.invalidateQueries({
      queryKey: ["employee", employee],
      exact: false,
    });
  },

  delete: async (id: number): Promise<void> => {
    await Server.delete("/employees/:id", {
      params: {
        id,
      },
      errorMessage: "Fehler beim Löschen des Mitarbeiters",
    });
    queryClient.invalidateQueries({
      queryKey: ["employee"],
      exact: false,
    });
  },

  getFutureAppointments: async (
    id: ID
  ): Promise<
    {
      id: number;
      start: SimpleDate;
    }[]
  > => {
    const res = await Server.get<
      {
        id: number;
        start: number;
      }[]
    >("/employees/futureAppointments/:id", {
      params: {
        id,
      },
      errorMessage: "Fehler beim Laden der zukünftigen Termine",
    });

    return res.map((app) => ({
      ...app,
      start: SimpleDate.importInt(app.start),
    }));
  },

  getAbsenses: async (
    id: ID,
    from: SimpleDate,
    to: SimpleDate
  ): Promise<EmployeeAbsense[]> => {
    const res = await Server.get<
      {
        day: number;
        type: AbsenseType;
        conflicts: number;
      }[]
    >("/employees/absences/:id/:from/:to", {
      params: {
        id,
        from: from.exportInt(),
        to: to.exportInt(),
      },
      errorMessage: "Fehler beim Laden der Abwesenheiten",
    });

    return res.map((absense) => ({
      day: SimpleDate.importInt(absense.day),
      type: absense.type,
      conflicts: absense.conflicts,
    }));
  },

  use(id: ID | undefined | null, options?: { enabled?: boolean }) {
    return useQuery<Employee>({
      queryKey: ["employee", id],
      queryFn: () => EmployeeServer.get(id!),
      enabled:
        id !== undefined &&
        id !== null &&
        id !== -1 &&
        (options?.enabled ?? true),
    });
  },

  useAll() {
    return useQuery({
      queryKey: ["employee"],
      queryFn: EmployeeServer.listAll,
    });
  },

  useFutureAppointments(id: ID) {
    return useQuery({
      queryKey: ["employee", id, "futureAppointments"],
      queryFn: () => EmployeeServer.getFutureAppointments(id),
      enabled: id !== -1,
    });
  },

  useAbsenses(id: ID, from: SimpleDate, to: SimpleDate) {
    return useQuery({
      queryKey: ["employee", id, "absenses", from.exportInt(), to.exportInt()],
      queryFn: () => EmployeeServer.getAbsenses(id, from, to),
      enabled: id !== -1,
    });
  },
};

export default EmployeeServer;

//@ts-ignore
window.getAbsenses = EmployeeServer.getAbsenses;
