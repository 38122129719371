import React from "react";
import { useNavigate, useParams } from "react-router";
import { Styles } from "../../../../Types";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FloatingLoadingButton from "../../FloatingLoadingButton";
import TextFieldWrapper from "../../TextFieldWrapper";
import { useSaveSettings } from "../../SaveSettingsProvider";
import { MultiplePickerTitles } from "../../Discounts/EditDiscount/MultiplePicker";
import { Service } from "../../../Server/Services/ServiceTypes";
import ServiceServer from "../../../Server/Services/ServiceServer";
import ServiceFunctions from "../../../Server/Services/Service.functions";
import TaskEditor from "./TaskEditor";
import Loading from "../../../Loading/Loading";
import CategoryServer from "../../../Server/Categories/CategoryServer";
import { Info } from "@mui/icons-material";

export const EMPTY_SERVICE: Service = {
  name: "",
  bookable: true,
  additional_serviceids: [],
  is_additional_service: false,
  no_additional_service_text: "",
  categoryid: -1,
  tasks: [
    {
      allowed_employees: [],
      id: 1,
      name: "Aufgabe 1",
      durations: [0, 30],
      follow_up_time: 0,
      preparation_time: 0,
      serviceid: -1,
      variation_index: 0,
      factor: 1,
    },
  ],
  id: -1,
};

export const styles: Styles = {
  wrapper: {
    width: "100%",
    padding: (theme) => theme.spacing(4, 4, 0, 4),
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
  },
  sideWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: (theme) => `0 0 calc(50% - ${theme.spacing(2)})`,
  },
  topWrapper: {
    marginBottom: (theme) => theme.spacing(3),
    width: "100%",
  },
  bottomWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  saveButton: {
    position: "absolute",
    right: (theme) => theme.spacing(4),
    bottom: (theme) => theme.spacing(4),
    // global style didn't work
    textTransform: "none",
  },
};

export default function EditService() {
  const navigate = useNavigate();
  const serviceid = parseInt(useParams<"serviceid">().serviceid || "");
  const { useSetChanged } = useSaveSettings();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const emptyService = React.useRef(ServiceFunctions.copy(EMPTY_SERVICE));

  const {
    data: initialService = emptyService.current,
    isLoading: loadingService,
  } = ServiceServer.use(serviceid);

  const [service, setService] = React.useState(
    ServiceFunctions.copy(EMPTY_SERVICE)
  );

  React.useEffect(() => {
    if (initialService) {
      setService(ServiceFunctions.copy(initialService));
    }
  }, [initialService]);

  const { data: categories = [] } = CategoryServer.useAll();

  const { data: services = [] } = ServiceServer.useAll();

  const additionalServices = React.useMemo(
    () => services.filter((service) => service.is_additional_service),
    [services]
  );

  // categories that have additional services
  const filteredCategories = React.useMemo(
    () =>
      categories.filter((category) =>
        category.service_ids.some((id) =>
          additionalServices.some((service) => service.id === id)
        )
      ),
    [categories, additionalServices]
  );

  // check if another service in the same category has the same name
  const nameConflict = React.useMemo(
    () =>
      services.some(
        (s) =>
          s.id !== service.id &&
          s.name === service.name &&
          s.categoryid === service.categoryid
      ),
    [services, service]
  );

  const { valid: serviceValid, error } = React.useMemo(
    () => ServiceFunctions.isValid(service),
    [service]
  );
  const changed = React.useMemo(
    () => !ServiceFunctions.isEqual(service, initialService || EMPTY_SERVICE),
    [service, initialService]
  );

  const save = async () => {
    if (service.categoryid === -1 || error) return;

    const id =
      await ServiceServer[service.id === -1 ? "create" : "update"](service);

    if (mounted.current && id) {
      setService({ ...service, id });
      navigate(window.location.pathname.replace("-1", id.toString()));
    }
  };

  useSetChanged({ error: !serviceValid, changed }, { save });

  // is enabled is false the service is deleted and can't be edited
  if (
    isNaN(serviceid) ||
    (initialService &&
      "enabled" in initialService &&
      !(initialService as any).enabled)
  )
    return (
      <Typography variant="h4" padding={4}>
        Invalide Service ID
      </Typography>
    );

  if ((loadingService && serviceid > 0) || !initialService) return <Loading />;

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.sideWrapper}>
        <Box sx={styles.topWrapper}>
          <TextFieldWrapper
            name="Titel:"
            tooltip={
              nameConflict
                ? "Es existiert bereits ein Service mit diesem Namen in dieser Kategorie."
                : undefined
            }
          >
            <TextField
              onChange={(e) =>
                setService((service) => ({
                  ...service,
                  name: e.target.value,
                }))
              }
              fullWidth
              error={service.name === "" || nameConflict}
              value={service.name || ""}
            />
          </TextFieldWrapper>
          <TextFieldWrapper name="Kategorie:">
            <FormControl fullWidth>
              <Select
                value={
                  categories.length && service.categoryid !== -1
                    ? service.categoryid
                    : ""
                }
                onChange={(e) => {
                  const category = categories.find(
                    (category) => category.id === e.target.value
                  );
                  if (category)
                    setService((service) => ({
                      ...service,
                      category,
                      categoryid: category.id,
                    }));
                }}
                error={!service.categoryid}
                fullWidth
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TextFieldWrapper>
        </Box>
        <Box sx={styles.bottomWrapper}>
          <TextFieldWrapper name="Online buchbar">
            <FormControlLabel
              control={
                <Checkbox
                  checked={service.bookable}
                  onChange={(_, bookable) =>
                    setService({ ...service, bookable })
                  }
                  sx={
                    !service.bookable
                      ? {
                          color: (theme) => theme.palette.error.main,
                          "&.Mui-checked": {
                            color: (theme) => theme.palette.error.main,
                          },
                        }
                      : undefined
                  }
                />
              }
              label=""
            />
            {service.bookable &&
              service.tasks.every((t) =>
                t.allowed_employees.every((t) => t.price === 0)
              ) && (
                <Tooltip
                  title={
                    <Typography>
                      Der Service ist kostenlos und online buchbar. Ist das
                      gewollt?
                    </Typography>
                  }
                >
                  <Info color="error" sx={{ verticalAlign: "middle" }} />
                </Tooltip>
              )}
          </TextFieldWrapper>
          <TextFieldWrapper name="Ist Zusatzservice">
            <FormControlLabel
              control={
                <Checkbox
                  checked={service.is_additional_service}
                  onChange={(_, is_additional_service) =>
                    setService({ ...service, is_additional_service })
                  }
                />
              }
              label={""}
            />
          </TextFieldWrapper>
          {!service.is_additional_service && (
            <>
              <TextFieldWrapper name="Kein Zusatzservice Text:">
                <TextField
                  onChange={(e) =>
                    setService((service) => ({
                      ...service,
                      no_additional_service_text: e.target.value,
                    }))
                  }
                  fullWidth
                  value={service.no_additional_service_text || ""}
                />
              </TextFieldWrapper>
              <Box width="80%" margin="auto">
                <Typography variant="h5">Zusatzservices</Typography>
                <MultiplePickerTitles
                  selected={services.filter((current) =>
                    service.additional_serviceids.includes(current.id)
                  )}
                  items={filteredCategories}
                  render={(item) =>
                    additionalServices.filter(
                      (service) => service.categoryid === item.id
                    )
                  }
                  onChange={(services) =>
                    setService({
                      ...service,
                      additional_serviceids: services.map(
                        (service) => service.id
                      ),
                    })
                  }
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box sx={styles.sideWrapper}>
        <TaskEditor
          tasks={ServiceFunctions.copyTasks(service.tasks)}
          onChange={(tasks) => {
            setService({ ...service, tasks });
          }}
          serviceid={service.id}
        />
      </Box>

      <FloatingLoadingButton
        tooltip={
          !serviceValid
            ? `Der Service ist aktuell fehlerhaft und kann nicht gespeichert werden: ${error}`
            : undefined
        }
        disabled={!serviceValid || nameConflict || !changed}
        onClick={() => save()}
      />
    </Box>
  );
}
