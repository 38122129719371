import { SimpleDate } from "@idot-digital/calendar-api";
import { CouponActivityLog } from "../../Server/ActivityLog/ActivityLogTypes";
import GenericLogDisplay from "./GenericLogDisplay";
import { stringifyPaymentMethod } from "../../Generic/PaymentMethodSelector";

export interface CouponLogDetailsProps {
  log: CouponActivityLog;
}

export default function CouponLogDetails(props: CouponLogDetailsProps) {
  return (
    <GenericLogDisplay
      data={[
        {
          name: "Preis",
          type: "money",
          data: props.log.amount,
        },
        {
          name: "Verkauft von",
          type: "string",
          data: props.log.employee_name ?? "Kein Mitarbeiter eingetragen",
        },
        {
          name: "Zahlungsmethode",
          type: "string",
          data: stringifyPaymentMethod(props.log.payment_method),
        },
        {
          name: "Erstellt am",
          type: "string",
          data: SimpleDate.fromDate(
            new Date(props.log.created_at)
          ).getDateString(),
        },
      ]}
    />
  );
}
