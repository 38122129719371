import { DragHandle } from "@mui/icons-material";
import { Box, Dialog, DialogProps, Paper, PaperProps } from "@mui/material";
import React from "react";
import Draggable from "react-draggable";

export interface DraggableDialogProps extends DialogProps {
  handle?: React.ReactNode;
}

export default function DraggableDialog(props: DraggableDialogProps) {
  const { handle, ...rest } = props;
  return <Dialog {...rest} PaperComponent={PaperComponent} />;
}

function PaperComponent(props: PaperProps) {
  const { children, ...rest } = props;
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  return (
    <Draggable
      cancel={'[class*="MuiDialogContent-root"]'}
      handle=".dialog-drag-handle"
      position={position}
      onDrag={(e, data) => {
        setPosition({ x: data.x, y: data.y });
      }}
      bounds="body"
    >
      <Paper {...rest}>
        <Box
          className="dialog-drag-handle"
          sx={{
            position: "absolute",
            top: 0,
            left: "calc(50% - 12px)",
            cursor: "move",
          }}
          onDoubleClick={() => {
            setPosition({ x: 0, y: 0 });
          }}
        >
          <DragHandle sx={{ color: (theme) => theme.palette.action.active }} />
        </Box>
        {children}
      </Paper>
    </Draggable>
  );
}
