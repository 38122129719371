import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Employee } from "../../Server/Employees/EmployeeTypes";
import TimePeriodPicker from "./TimePeriodPicker";
import { AbsoluteAppointmentPeriod } from "../../Server/Discounts/DiscountTypes";
import React from "react";
import { SimpleDate } from "@idot-digital/calendar-api";
import EmployeeServer from "../../Server/Employees/EmployeeServer";
import { AbsenseType } from "../../Server/WorkingHours/WorkhoursTypes";
import { stringifyAbsenseType } from "../../Server/Appointments/AppointmentTypes";

export interface EmployeeAbsenseProps {
  employee: Employee;
}

export default function EmployeeAbsense(props: EmployeeAbsenseProps) {
  const [timeperiod, setTimePeriod] = React.useState<AbsoluteAppointmentPeriod>(
    {
      end: SimpleDate.now().setHours(23, 59),
      start: SimpleDate.now().setHours(0, 0).add(0, 0, -30),
    }
  );

  const { data: absence } = EmployeeServer.useAbsenses(
    props.employee.id,
    timeperiod.start,
    timeperiod.end
  );

  const { daysAbsent, daysIll } = React.useMemo(() => {
    if (!absence) return { daysAbsent: 0, daysIll: 0 };
    return absence.reduce(
      (acc, absense) => {
        if (absense.type === AbsenseType.sick) acc.daysIll++;
        acc.daysAbsent++;
        return acc;
      },
      { daysAbsent: 0, daysIll: 0 }
    );
  }, [absence]);

  return (
    <Box
      p={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
    >
      <TimePeriodPicker
        period={timeperiod}
        onChange={(period) => setTimePeriod(period)}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        gap={4}
        maxWidth={800}
      >
        <Box
          sx={{
            aspectRatio: 1,
            borderRadius: "50%",
            p: 3,
            flex: "1 1 0",
            border: (theme) => `4px solid ${theme.palette.primary.main}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            minWidth: 200,
          }}
        >
          <Typography variant="h2" color="primary">
            {daysAbsent} Tage
          </Typography>
          <Typography>Abwesend</Typography>
        </Box>
        <Box flex="1 1 0" />
        <Box
          sx={{
            aspectRatio: 1,
            borderRadius: "50%",
            p: 3,
            flex: "1 1 0",
            border: (theme) => `4px solid ${theme.palette.primary.main}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            minWidth: 200,
          }}
        >
          <Typography variant="h2" color="primary">
            {daysIll} Tage
          </Typography>
          <Typography>davon krank</Typography>
        </Box>
      </Box>
      <Table sx={{ maxWidth: 800 }}>
        <TableHead>
          <TableRow
            sx={{
              th: {
                color: (theme) => theme.palette.primary.main,
                fontWeight: "bold",
              },
            }}
          >
            <TableCell>Grund</TableCell>
            <TableCell>am</TableCell>
            <TableCell>Terminkonflikte</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {absence?.map((absence) => (
            <TableRow key={absence.day.exportDayInt() + "|" + absence.type}>
              <TableCell>{stringifyAbsenseType(absence.type)}</TableCell>
              <TableCell>{absence.day.getDateString()}</TableCell>
              <TableCell>{absence.conflicts}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {absence ? (
        <Typography variant="caption" textAlign="center" width="100%">
          Keine weiteren Einträge
        </Typography>
      ) : (
        <Skeleton width={800} height={48} />
      )}
    </Box>
  );
}
