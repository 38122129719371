import React from "react";
import { Task } from "../../../Server/Services/ServiceTypes";
import {
  Box,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ID, Styles } from "../../../../Types";
import DurationsPicker from "./DurationsPicker";
import { Add, Delete, Info } from "@mui/icons-material";
import NumberInput from "../../../Generic/NumberInput";
import EmployeePriceSelector from "./EmployeePriceSelector";
import EmployeeServer from "../../../Server/Employees/EmployeeServer";
import { joinWithSpecialLast } from "../../../../Functions/functions";

export interface TaskVarietyEditorProps {
  tasks: Task[];
  onChange: (tasks: Task[]) => void;
  serviceid: ID;
}

const styles = {
  wrapper: {
    width: "100%",
  },
  taskWrapper: {
    padding: (theme) => theme.spacing(2),
    position: "relative",
  },
  task: {
    "&:nth-of-type(even)": {
      background: (theme) => theme.palette.background.paper,
    },
  },
  head: {
    display: "grid",
    alignItems: "flex-end",
    gridTemplateColumns: "1fr 1fr auto",
    gap: 2,
  },
  deleteButton: {
    top: (theme) => theme.spacing(-1),
    right: (theme) => theme.spacing(-1),
    position: "absolute",
  },
  extraTimeWrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: (theme) => theme.spacing(7.5),
    marginTop: (theme) => theme.spacing(1),
    ">*": {
      flexGrow: 1,
    },
  },
  durationSplitter: {
    padding: (theme) => theme.spacing(1, 1, 0),
    flexGrow: 0,
  },

  infoWrapper: {
    width: (theme) => theme.spacing(6),
    flex: (theme) => `0 0 ${theme.spacing(6)}`,
    pl: 1,
    mb: 0.5,
  },
} satisfies Styles;

export default function TaskVarietyEditor(props: TaskVarietyEditorProps) {
  const { data: employees = [] } = EmployeeServer.useAll();

  const employeesWithoutPrice = React.useMemo(() => {
    const taskEmployees = new Map<
      number,
      {
        shortName: string;
        id: number;
      }[]
    >();

    for (const task of props.tasks) {
      const employeesWithoutPrice = task.allowed_employees
        .filter((e) => !e.price)
        .map((e) => e.employeeid);
      if (employeesWithoutPrice.length === task.allowed_employees.length)
        continue;
      taskEmployees.set(
        task.id,
        employeesWithoutPrice.map((id) => {
          const employee = employees.find((e) => e.id === id);
          return employee || { shortName: "[Fehler]", id: -1 };
        })
      );
    }
    return taskEmployees;
  }, [props.tasks, employees]);

  return (
    <Grid container sx={styles.wrapper} spacing={2}>
      <Grid item xs={11}>
        {props.tasks.map((task, index) => (
          <Box key={task.id + "|" + index} sx={styles.task}>
            <Paper sx={styles.taskWrapper}>
              <Box sx={styles.head}>
                <TextField
                  error={!task.name}
                  label="Name"
                  value={task.name}
                  onChange={(e) => {
                    // works due to pointer
                    task.name = e.target.value;
                    props.onChange(props.tasks);
                  }}
                  sx={{
                    gridColumn: "1 / span 2",
                  }}
                />
                <Box sx={styles.infoWrapper} />
                <FormControl variant="standard">
                  <InputLabel htmlFor={`task-factor-${task.id}`}>
                    Faktor
                  </InputLabel>
                  <NumberInput
                    onChange={(val) => {
                      // works due to pointer
                      task.factor = val;
                      props.onChange(props.tasks);
                    }}
                    fullWidth
                    value={task.factor}
                    maxDecimals={0}
                    id={`task-factor-${task.id}`}
                  />
                </FormControl>
                <EmployeePriceSelector
                  allowed_employees={task.allowed_employees}
                  onChange={(allowed_employees) => {
                    const newTasks = props.tasks.map((t) => {
                      if (t.id === task.id) {
                        return {
                          ...t,
                          allowed_employees,
                        };
                      }
                      return t;
                    });
                    props.onChange(newTasks);
                  }}
                />
                <Box sx={styles.infoWrapper}>
                  {(employeesWithoutPrice.get(task.id)?.length || 0) > 0 && (
                    <Tooltip title="Einige Mitarbeiter sind kostenlos, während andere Preise haben">
                      <Info color="error" />
                    </Tooltip>
                  )}
                </Box>
              </Box>
              <Box sx={styles.extraTimeWrapper}>
                <FormControl>
                  <InputLabel variant="standard" shrink>
                    Vorbereitungszeit
                  </InputLabel>
                  <NumberInput
                    value={task.preparation_time}
                    error={task.preparation_time > 99}
                    onChange={(value) => {
                      // works due to pointer
                      task.preparation_time = value as number;
                      props.onChange(props.tasks);
                    }}
                  />
                </FormControl>
                <Typography sx={styles.durationSplitter}>-</Typography>
                <FormControl>
                  <InputLabel variant="standard" shrink>
                    Nachbereitungszeit
                  </InputLabel>
                  <NumberInput
                    value={task.follow_up_time}
                    error={task.follow_up_time > 99}
                    onChange={(value) => {
                      // works due to pointer
                      task.follow_up_time = value as number;
                      props.onChange(props.tasks);
                    }}
                  />
                </FormControl>
              </Box>
              <DurationsPicker
                durations={task.durations}
                onChange={(durations) => {
                  task.durations = durations;
                  props.onChange(props.tasks);
                }}
              />
              {props.tasks.length > 1 && (
                <Fab
                  sx={styles.deleteButton}
                  size="small"
                  color="primary"
                  onClick={() => {
                    const newTasks = props.tasks.filter(
                      (t) => t.id !== task.id
                    );

                    props.onChange(newTasks);
                  }}
                >
                  <Delete />
                </Fab>
              )}
            </Paper>
          </Box>
        ))}
      </Grid>
      <Grid item xs={1}>
        <IconButton
          sx={{
            background: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
          }}
          onClick={() => {
            const newTasks = [
              ...props.tasks,
              {
                id: Math.min(...props.tasks.map((t) => t.id), 0) - 1,
                serviceid: props.serviceid,
                name: "",
                allowed_employees: [],
                durations: [0, 30],
                preparation_time: 0,
                follow_up_time: 0,
                variation_index: props.tasks[0].variation_index,
                factor: 1,
              } as Task,
            ];

            props.onChange(newTasks);
          }}
        >
          <Add />
        </IconButton>
      </Grid>
    </Grid>
  );
}
