import parsePhoneNumberLib, {
  PhoneNumber,
  isValidNumber,
} from "libphonenumber-js";

class NoPhoneNumberError extends Error {
  constructor() {
    super("Telefonnummer ist nicht gültig");
  }
}

/**
 * Parse input to phone number object
 * @param input to be parsed
 * @returns PhoneNumber object or thrown NoPhoneNumberError
 */
export const parsePhone = (input: string): PhoneNumber | undefined => {
  try {
    // Catch error when parsing invalid phone number
    return parsePhoneNumberLib(input, "DE");
  } catch (_) {
    throw new NoPhoneNumberError();
  }
};

/**
 * Parse phone number from string
 * @param input to be parsed
 * @returns parsed phone number or thrown NoPhoneNumberError
 */
export const parsePhoneNumber = (input: string): string => {
  const phone = parsePhone(input);
  return phone?.formatInternational() ?? input;
};

/**
 * Check if phone number is valid
 * @param input to be checked
 * @returns true, if input is a valid phone number
 */
export const isValidPhoneNumber = (input: string): boolean => {
  try {
    return isValidNumber(input);
  } catch (_) {
    return false;
  }
};
