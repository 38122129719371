import React from "react";

import { CalendarViewWeekOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

import {
  SimpleDuration,
  SimpleDate,
  EqualityCheck,
} from "@idot-digital/calendar-api";

import { ID, Styles } from "../../Types";
import CalendarRenderer from "./Rendering/CalendarRenderer";
import DayPicker from "./DayPicker/DayPicker";
import AppointmentNode from "./Appointment/AppointmentNode";
import { Node } from "./Rendering/CalendarRenderer";
import {
  calculateDynamicWorkingHours,
  getFreeTimes,
  moveDurationHelper,
  workingHoursLabel,
} from "./Calendar.functions";
import config from "../../config";
import { CalendarProps } from "./CalendarManager";
import Loading from "../Loading/Loading";
import { dayCalendarTimeLine } from "./TimeLine/TimeLine.functions";
import EmployeeServer from "../Server/Employees/EmployeeServer";
import WorkhoursServer from "../Server/WorkingHours/WorkhoursServer";
import {
  ActualWorkhoursType,
  DayOff,
  DEFAULT_WORKHOURS,
  WorkhoursType,
} from "../Server/WorkingHours/WorkhoursTypes";
import AppointmentServer from "../Server/Appointments/AppointmentServer";
import {
  AppointmentDurations,
  ListAppointment,
} from "../Server/Appointments/AppointmentTypes";
import AbsenseNode from "./Appointment/AbsenseNode";
import EmployeeAvatar from "../Settings/Employees/EmployeeAvatar";
import CalendarContextMenu from "./CalendarContextMenu";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { areOnSameDay } from "../../Functions/functions";

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(1),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    overflow: "hidden",
  },
  subheader: {
    display: "flex",
    "> *": {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "> *:first-of-type > *:last-of-type": {
      marginRight: "auto",
    },
    "> *:last-of-type > *:first-of-type": {
      marginLeft: "auto",
    },
  },
  innerWrapper: {
    flex: (theme) => `1 1 calc(100% - ${theme.spacing(7)})`,
    height: (theme) => `calc(100% - ${theme.spacing(7)})`,
  },
  weekdayLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    borderBottom: (theme) => `${theme.spacing(0.125)} solid transparent`,
    borderRadius: 1,
  },
};

export default function DayCalendar(props: CalendarProps) {
  const { data: allEmployees = [], isSuccess: employeesLoaded } =
    EmployeeServer.useAll();

  const [day, setDay] = React.useState(props.startDay || SimpleDate.now());

  React.useEffect(() => {
    if (props.startDay) setDay(props.startDay);
  }, [props.startDay]);

  const { data: appointments = [], isSuccess: appointmentsLoaded } =
    AppointmentServer.useDay(day);

  const { data: workhours = [], isSuccess: workhoursLoaded } =
    WorkhoursServer.useActualPeriod(
      allEmployees.map(({ id }) => id),
      day,
      day,
      {
        enabled: employeesLoaded,
      }
    );

  // hide employees that have not workhours
  const employees = React.useMemo(() => {
    const filtered = allEmployees.filter(
      (emp) =>
        workhours.some(
          (w) => w.employeeid === emp.id && w.workhour && !w.absenses
        ) ||
        appointments.some((a) =>
          a.durations.some((d) => d.employeeid === emp.id)
        )
    );
    if (filtered.length === 0) return allEmployees;
    return filtered;
  }, [allEmployees, workhours, appointments]);

  // get earliest work hour start and latest work hour end to have everything in view
  const workhoursMax = React.useMemo(() => {
    const max = workhours.reduce(
      (acc, { workhour: workhours }) => {
        if (!workhours) return acc;
        if (workhours.start.exportInt() === workhours.end.exportInt())
          return acc;
        if (!acc)
          return {
            start: workhours.start,
            end: workhours.end,
          };

        if (acc.start.isTimeEqual(workhours.start) === EqualityCheck.earlier)
          acc.start = workhours.start.copy();

        if (acc.end.isTimeEqual(workhours.end) === EqualityCheck.later)
          acc.end = workhours.end.copy();
        return acc;
      },
      null as null | {
        start: SimpleDate;
        end: SimpleDate;
      }
    ) || {
      start: DEFAULT_WORKHOURS().start,
      end: DEFAULT_WORKHOURS().end,
    };
    max.end = max.start.copy().setHours(max.end.getHour(), max.end.getMinute());
    return max;
  }, [workhours]);

  const loaded = React.useMemo(
    () => employeesLoaded && workhoursLoaded && appointmentsLoaded,
    [employeesLoaded, workhoursLoaded, appointmentsLoaded]
  );

  // get min and max for calendar -> min is earliest appointment or earliest workhour start, max is latest appointment or latest workhour end
  // round to nearest hour to have full hours in view -> full rows
  const calendarMinMax = React.useMemo(
    () =>
      calculateDynamicWorkingHours(
        appointments
          .flatMap((appointment) =>
            appointment.durations.map(
              (duration) =>
                new SimpleDuration(
                  duration.start,
                  duration.start
                    .copy()
                    .setHours(duration.end.getHour(), duration.end.getMinute())
                )
            )
          )
          .concat(new SimpleDuration(workhoursMax.start, workhoursMax.end))
      ),
    [appointments, workhoursMax]
  );

  const workingHours = React.useMemo(
    () => workingHoursLabel(calendarMinMax),
    [calendarMinMax]
  );

  const startOffset = React.useMemo(
    () =>
      calendarMinMax.getBeginning().getHour() +
      calendarMinMax.getBeginning().getMinute() / 60,
    [calendarMinMax]
  );

  const endOffset = React.useMemo(
    () => startOffset + calendarMinMax.getDuration() / 60,
    [startOffset, calendarMinMax]
  );

  const timeLines = React.useMemo(() => {
    return employees.flatMap((employee, index) => {
      const workhour = workhours.find((w) => w.employeeid === employee.id);
      if (!workhour || !workhour.workhour) return [];
      const freeTimes = getFreeTimes(employee.id, day, workhour, appointments);

      return freeTimes.flatMap((freeTime) => {
        const TIME_LINE_INTERVAL = 15;
        // round freetime to next 15 minutes
        const start = freeTime.getBeginning().copy();
        start.add(
          TIME_LINE_INTERVAL - (start.getMinute() % TIME_LINE_INTERVAL)
        );
        if (start.isEqual(freeTime.getEnd()) !== EqualityCheck.later) return [];

        const roundedFreeTime = new SimpleDuration(start, freeTime.getEnd());
        const startTime =
          roundedFreeTime.getBeginning().getHour() +
          roundedFreeTime.getBeginning().getMinute() / 60;
        // loop through free times with 15 min intervals
        const timeLines = [];
        for (
          let offset = 0;
          offset < roundedFreeTime.getDuration();
          offset += TIME_LINE_INTERVAL
        ) {
          const TIME_LINE_HEIGHT = 0.2;

          timeLines.push({
            column: index,
            row: startTime + offset / 60 - startOffset - TIME_LINE_HEIGHT / 2,
            width: 1,
            height: TIME_LINE_HEIGHT,
            sx: {
              pointerEvents: "none",
            },
            key: `${employee.id}-${startTime + offset}`,
            component: (
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                height="100%"
                alignItems="center"
                sx={{ opacity: 0.6 }}
              >
                <Box
                  height=".05rem"
                  marginRight={(theme) => theme.spacing(1)}
                  bgcolor="#696969"
                  flexGrow={1}
                  marginLeft="33%"
                ></Box>
                <Typography
                  color={"#696969"}
                  variant="caption"
                  fontSize="0.6em"
                  lineHeight="0.6em"
                >
                  {roundedFreeTime
                    .getBeginning()
                    .copy()
                    .add(offset)
                    .getTimeString()}
                </Typography>
                <Box
                  height=".05rem"
                  marginLeft={(theme) => theme.spacing(1)}
                  bgcolor="#696969"
                  flexGrow={1}
                  marginRight="33%"
                ></Box>
              </Box>
            ),
          });
        }

        return timeLines;
      });
    });
    //eslint-disable-next-line
  }, [employees, day, workhours, appointments]);

  const appointmentNode = (
    appointment: ListAppointment,
    duration: AppointmentDurations,
    isFirst: boolean,
    isLast: boolean,
    index: number
  ): Node | null => {
    const start = duration.start.getHour() + duration.start.getMinute() / 60;

    // Map duration to working hours
    const row = Math.max(start - startOffset, 0);

    // Reduce height according to start offset
    const height = Math.min(
      Math.max(
        new SimpleDuration(duration.start, duration.end).getDuration() / 60 -
          Math.max(startOffset - start, 0),
        0
      ),
      endOffset - Math.max(startOffset - start, start)
    );

    const width = 1;

    const employeeIndex = employees.findIndex(
      (employee) => employee.id === duration.employeeid
    );

    return height > 0 && employeeIndex >= 0
      ? {
          row,
          column: employeeIndex,
          width,
          height,
          key:
            duration.start.getTimeString() +
            ":" +
            duration.end.getDateTimeString() +
            ":-1:" +
            duration.employeeid.toString() +
            ":" +
            appointment.id.toString(),
          sx: { zIndex: 0 },
          component: (
            <AppointmentNode
              appointment={appointment}
              index={index}
              duration={duration}
              width={width}
              height={height}
              isFirst={isFirst}
              isLast={isLast}
              onClick={() => props.openAppointment(appointment)}
              // make padding relativ to the height because that is how css calculates it later
              startPadding={(duration.preparation_time / 60 / height) * 100}
              endPadding={(duration.follow_up_time / 60 / height) * 100}
            />
          ),
        }
      : null;
  };

  const absenseNode = React.useCallback(
    (
      workhour: SimpleDuration,
      type: ActualWorkhoursType,
      employeeid: ID,
      altBackground = false
    ): Node => {
      const start = workhour.start.getHour() + workhour.start.getMinute() / 60;
      const row = Math.max(start - startOffset, 0);

      // Reduce height according to start offset
      const height = Math.min(
        Math.max(
          new SimpleDuration(workhour.start, workhour.end).getDuration() / 60 -
            Math.max(startOffset - start, 0),
          0
        ),
        endOffset - Math.max(startOffset - start, start)
      );

      const width = 1;

      const employeeIndex = employees.findIndex(({ id }) => id === employeeid);

      return {
        row,
        column: employeeIndex,
        width,
        height,
        clickthrough: true,
        key:
          workhour.start.getTimeString() +
          ":" +
          workhour.end.getDateTimeString() +
          ":" +
          type.toString() +
          ":" +
          employeeid.toString(),
        sx: {
          zIndex: 1,
          pointerEvents: "none",
        },
        component: (
          <AbsenseNode
            duration={new SimpleDuration(workhour.start, workhour.end)}
            type={type}
            height={height}
            altBackground={altBackground}
          />
        ),
      };
    },
    [employees, startOffset, endOffset]
  );

  const absenseNodes = React.useMemo((): Node[] => {
    return workhours.flatMap(
      ({ absenses, breakTime, workhour: workhours, employeeid }) => {
        // employee is hidden, since they have not workhours
        if (!employees.some((e) => e.id === employeeid)) return [];
        const nodes = [];
        const hasAppointments = appointments.some((a) =>
          a.durations.some((d) => d.employeeid === employeeid)
        );
        if (absenses) {
          const duration = new SimpleDuration(absenses.start, absenses.end);
          const node = absenseNode(
            duration,
            absenses.type,
            employeeid,
            hasAppointments
          );
          // when absense is full day, do not display workhours and break
          if (
            absenses.start.getMinutesOfDay() <=
              calendarMinMax.start.getMinutesOfDay() &&
            absenses.end.getMinutesOfDay() >=
              calendarMinMax.end.getMinutesOfDay()
          ) {
            return [node];
          }
          nodes.push(node);
        }
        if (workhours) {
          const start = calendarMinMax.start
            .copy()
            .setHours(workhours.start.getHour(), workhours.start.getMinute());
          const end = calendarMinMax.start
            .copy()
            .setHours(workhours.end.getHour(), workhours.end.getMinute());
          if (start.exportInt() > calendarMinMax.start.exportInt()) {
            // employee starts working after start of calendar -> display non working time until start of work
            const duration = new SimpleDuration(calendarMinMax.start, start);
            nodes.push(
              absenseNode(duration, WorkhoursType.workhours, employeeid)
            );
          }
          if (end.exportInt() < calendarMinMax.end.exportInt()) {
            // employee ends working before end of calendar -> display non working time after end of work
            const duration = new SimpleDuration(end, calendarMinMax.end);
            nodes.push(
              absenseNode(duration, WorkhoursType.workhours, employeeid)
            );
          }
        } else {
          // employee does not work on this day -> add full day (entire currently visible day) absense
          // since employee does not work, no break is displayed
          return [
            absenseNode(
              calendarMinMax.copy(),
              DayOff,
              employeeid,
              hasAppointments
            ),
          ];
        }
        if (breakTime) {
          const duration = new SimpleDuration(breakTime.start, breakTime.end);
          nodes.push(absenseNode(duration, WorkhoursType.break, employeeid));
        }
        return nodes;
      }
    );
  }, [workhours, calendarMinMax, absenseNode, appointments]);

  const [calendarClickInfo, setCalendarClickInfo] = React.useState<{
    x: number;
    y: number;
    employeeid: number;
    time: SimpleDate;
  } | null>(null);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.subheader}>
        <Box />
        <DayPicker onChange={(date) => setDay(date)} value={day} />
        <Box>
          <Button
            variant="text"
            color="inherit"
            onClick={() => setDay(SimpleDate.now())}
            endIcon={<RestartAltIcon />}
            disabled={areOnSameDay(day, SimpleDate.now())}
          >
            Zurück zu heute
          </Button>
          <Button
            variant="text"
            color="inherit"
            onClick={() => props.switchView(day)}
            endIcon={<CalendarViewWeekOutlined />}
          >
            Wochenansicht
          </Button>
        </Box>
      </Box>
      <Box sx={styles.innerWrapper}>
        {!loaded ? (
          <Loading />
        ) : (
          <>
            <CalendarRenderer
              onClick={(info) => {
                const employeeid = employees[Math.floor(info.column)]?.id;

                const time = SimpleDate.fromDate(
                  moveDurationHelper(
                    new Date(day.toDate()),
                    info.row,
                    startOffset,
                    calendarMinMax,
                    workingHours.length,
                    appointments
                  )
                );

                if (!employeeid) return;
                if (props.gettingTimes)
                  props.createAppointment(employeeid, time.toDate());
                else
                  setCalendarClickInfo({
                    x: info.mouseX,
                    y: info.mouseY,
                    employeeid,
                    time,
                  });
              }}
              columnLabels={employees.map((employee) => (
                <Box
                  sx={{
                    ...styles.weekdayLabel,
                    background: employee.color,
                    color:
                      config.employeeColors.find(
                        (color) => color.color === employee.color
                      )?.contrastText || "#fff",
                  }}
                >
                  <EmployeeAvatar employee={employee} />
                </Box>
              ))}
              content={
                // absense times
                absenseNodes
                  // time lines
                  .concat(timeLines)
                  // appointments
                  .concat(
                    appointments
                      // sort for correct color shifting
                      .sort(
                        (a, b) =>
                          b.durations[0].start.getMinutesOfDay() -
                          a.durations[0].start.getMinutesOfDay()
                      )
                      .flatMap(
                        (appointment, index) =>
                          appointment.durations
                            .map((duration, durIndex) =>
                              duration.start.exportInt() ===
                              duration.end.exportInt()
                                ? null
                                : appointmentNode(
                                    appointment,
                                    duration,
                                    durIndex === 0,
                                    // last that is not 0 min long
                                    !appointment.durations.some(
                                      (d, i) =>
                                        i > durIndex &&
                                        d.end.exportInt() -
                                          d.start.exportInt() >
                                          0
                                    ),
                                    index
                                  )
                            )
                            .filter(Boolean) as Node[]
                      )
                  )
                  .concat(
                    dayCalendarTimeLine(
                      day,
                      employees.length,
                      startOffset,
                      endOffset
                    )
                  )
              }
              rowLabels={workingHours}
            />
            <CalendarContextMenu
              context={calendarClickInfo}
              onClose={() => setCalendarClickInfo(null)}
              openAppointment={(employeeid, startTime) =>
                props.createAppointment(employeeid, startTime.toDate())
              }
              actualWorkhours={workhours}
            />
          </>
        )}
      </Box>
    </Box>
  );
}
