const config = {
  api_link: "main",
  login_server_link: "login",
  fileAPIPath: "upload/upload",
  fileRootPath: "upload/uploads",
  flagsBaseURL: "/flags/",
  serverRoot: import.meta.env.DEV
    ? window.location.origin + "/api"
    : "https://app.emmasy.com/api",

  employeeColors: [
    { color: "#C0564B", contrastText: "#fff" },
    { color: "#B28C00", contrastText: "#3D3D3D" },
    { color: "#9DAE1F", contrastText: "#fff" },
    { color: "#9EB568", contrastText: "#fff" },
    { color: "#00BED8", contrastText: "#fff" },
    { color: "#C2FCF2", contrastText: "#3D3D3D" },
    { color: "#2F8379", contrastText: "#fff" },
    { color: "#009688", contrastText: "#fff" },
    { color: "#2F4858", contrastText: "#fff" },
    { color: "#FFC37A", contrastText: "#fff" },
    { color: "#FFC2B0", contrastText: "#3D3D3D" },
    { color: "#CDA291", contrastText: "#fff" },
    { color: "#B4AA99", contrastText: "#3D3D3D" },
    { color: "#A26952", contrastText: "#fff" },
    { color: "#6d4c41", contrastText: "#fff" },
    { color: "#4D4637", contrastText: "#fff" },
  ],
  languages: [
    { name: "deutsch", icon: "/flags/w40/de.png" },
    { name: "englisch", icon: "/flags/w40/us.png" },
    { name: "französisch", icon: "/flags/w40/fr.png" },
    { name: "russisch", icon: "/flags/w40/ru.png" },
    {
      name: "andere",
      icon: "/flags/andere_flagge.jpg",
    },
  ],
  features: [
    {
      id: "discounts",
      name: "Rabatte",
      description: "Rabatte für spezielle Produkte, Zeiträume und Mitarbeiter",
    },
    {
      id: "employee_login",
      name: "Mitarbeiter Login",
      description:
        "Mitarbeiter können sich in einem separaten Login mit einem Pin anmelden, wenn der Laden Account angemeldet ist",
    },
    {
      id: "stats",
      name: "Statistiken",
      description: "Statistiken über Einnahmen einzelner Mitarbeiter",
    },
    // {
    //   id: "factor",
    //   name: "Faktor für Dienstleistungen",
    //   description: "Faktor zum Gewichten von Dienstleistungen in der Statistik",
    //   dependencies: ["stats"],
    // },
  ] as {
    id: string;
    name: string;
    description: string;
    dependencies?: string[];
  }[],
  currency_signs: ["€", "CHF", "$", "£", "¥"],

  idleTimeout: {
    employee: 60 * 1000 * 5,
    store: 30 * 1000,
  },

  appointmentCacheTime: 1000 * 60 * 2,

  devLogin: import.meta.env.DEV
    ? {
        username: "admin",
        password: "Sicherheit1!",
        // username: "idot-digital",
        // password: "2nac$G75n@Pcg^1zIJwYUIOUILtib^2I",
        disableIdleLogout: true,
        disable: false,
      }
    : {},

  contact: {
    email: "support@idot-digital.de",
    phone: "+49 241 56003009",
  },
};

export default config;
