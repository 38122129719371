import React from "react";
import {
  Box,
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Styles } from "../../Types";
import {
  AccountBox,
  ArrowBack,
  ChromeReaderMode,
  Close,
  PeopleAlt,
  Person,
  SettingsApplications,
  Percent,
  WorkOutline,
  CalendarToday,
  ThumbUpAlt,
  Update,
  Cancel,
  TimerOutlined,
} from "@mui/icons-material";
import { Outlet, Route, Routes, useNavigate, useParams } from "react-router";
import Sidebar from "./Sidebar";
import { useSaveSettings } from "./SaveSettingsProvider";
import { redirectToRoot } from "../Root/Root.functions";
import { useServer } from "../Server/ServerContext";
import EmployeeServer from "../Server/Employees/EmployeeServer";

const employeeSidebar = [
  {
    label: "Allgemein",
    link: "info",
    icon: <AccountBox />,
  },
  {
    label: "Arbeitszeiten",
    link: "workhours",
    icon: <WorkOutline />,
  },
  {
    label: "Abwensenehti",
    link: "absence",
    icon: <TimerOutlined />,
  },
];

export const notificationSidebar = [
  {
    label: "Allgemein",
    link: "/settings/general/notifications/general",
    icon: <SettingsApplications />,
    admin: true,
  },
  {
    label: "Buchungsbestätigung",
    link: "/settings/general/notifications/book/email",
    alternativeLinks: ["/settings/general/notifications/book/sms"],
    icon: <CalendarToday />,
    admin: true,
  },
  {
    label: "Erinnerung",
    link: "/settings/general/notifications/reminder/email",
    alternativeLinks: ["/settings/general/notifications/reminder/sms"],
    icon: <ThumbUpAlt />,
    admin: true,
  },
  {
    label: "Änderung",
    link: "/settings/general/notifications/update/email",
    alternativeLinks: ["/settings/general/notifications/update/sms"],
    icon: <Update />,
    admin: true,
  },
  {
    label: "Stornierung",
    link: "/settings/general/notifications/cancel/email",
    alternativeLinks: ["/settings/general/notifications/cancel/sms"],
    icon: <Cancel />,
    admin: true,
  },
];

export const settingsSidebar = [
  {
    label: "Leistungen",
    icon: <ChromeReaderMode />,
    link: "/settings/services",
    admin: true,
  },
  {
    label: "Personal",
    icon: <PeopleAlt />,
    link: "/settings/employees",
    admin: true,
  },
  {
    label: "Kunden",
    icon: <Person />,
    link: "/settings/customers",
  },
  {
    label: "Rabatte",
    icon: <Percent />,
    link: "/settings/discounts",
    admin: true,
    featureIDs: ["discounts"],
  },
  {
    label: "Einstellungen",
    icon: <SettingsApplications />,
    link: "/settings/general",
    admin: true,
  },
];

const styles: Styles = {
  dialog: {
    height: "75vh",
    borderRadius: 0,
    display: "flex",
  },
  inner: {
    height: "100%",
    flex: "1 0 0",
    overflow: "hidden",
    position: "relative",
    backgroundColor: (theme) => theme.palette.background.default,
  },
  scrollWrapper: {
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  button: {
    color: (theme) => theme.palette.primary.contrastText,
  },
};

export default function SettingsWrapper() {
  const navigate = useNavigate();
  const employeeid = (() => {
    const id = parseInt(useParams<{ id: string }>().id || "");
    if (isNaN(id)) return undefined;
    return id;
  })();
  const { account } = useServer();

  const [open, setOpen] = React.useState(Boolean(account));
  React.useEffect(() => setOpen(Boolean(account)), [account]);

  const { data: currentEmployee } = EmployeeServer.use(employeeid, {
    enabled: window.location.href.includes("employees"),
  });

  const { checkForChanges } = useSaveSettings();

  const shouldRedirect = async (target: string) => {
    if (await checkForChanges())
      target === "/" ? redirectToRoot(navigate) : navigate(target);
  };

  const close = () => {
    // Wait for close animation to finish
    // Trigger redirect onExited
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        PaperProps={{ sx: { borderRadius: 3, backgroundColor: "unset" } }}
        // Exit animation
        onClose={close}
        TransitionProps={{ onExited: () => navigate("/") }}
      >
        <AppBar position="static" color="primary" elevation={0}>
          <Toolbar>
            <Routes>
              <Route
                path="employees/:employeeid/*"
                element={
                  <>
                    <IconButton
                      size="large"
                      onClick={() => shouldRedirect("/settings/employees")}
                      edge="start"
                      sx={styles.button}
                    >
                      <ArrowBack />
                    </IconButton>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    >
                      Personal bearbeiten
                      <Typography
                        variant="subtitle1"
                        component="span"
                        marginLeft={1}
                      >
                        {currentEmployee?.shortName || ""}
                      </Typography>
                    </Typography>
                  </>
                }
              />
              <Route
                path="general/notifications/*"
                element={
                  <>
                    <IconButton
                      size="large"
                      onClick={() => shouldRedirect("/settings/general")}
                      edge="start"
                      sx={styles.button}
                    >
                      <ArrowBack />
                    </IconButton>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    >
                      Benachrichtigungen
                    </Typography>
                  </>
                }
              />
              <Route
                path="general/general"
                element={
                  <>
                    <IconButton
                      size="large"
                      onClick={() => shouldRedirect("/settings/general")}
                      edge="start"
                      sx={styles.button}
                    >
                      <ArrowBack />
                    </IconButton>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    >
                      Allgemein
                    </Typography>
                  </>
                }
              />
              <Route
                path="general/features"
                element={
                  <>
                    <IconButton
                      size="large"
                      onClick={() => shouldRedirect("/settings/general")}
                      edge="start"
                      sx={styles.button}
                    >
                      <ArrowBack />
                    </IconButton>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    >
                      Features
                    </Typography>
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Einstellungen
                  </Typography>
                }
              />
            </Routes>
            <IconButton
              size="large"
              edge="start"
              onClick={() => shouldRedirect("/")}
              sx={styles.button}
            >
              <Close fontSize="large" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={styles.dialog}>
          <Routes>
            <Route
              path="employees/:employeeid/*"
              element={<Sidebar items={employeeSidebar} />}
            />
            <Route
              path="general/notifications/*"
              element={<Sidebar items={notificationSidebar} checkForChanges />}
            />
            <Route
              path="*"
              element={<Sidebar items={settingsSidebar} checkForChanges />}
            />
          </Routes>
          <Box sx={styles.inner}>
            <Box sx={styles.scrollWrapper}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
