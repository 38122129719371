import { SimpleDate } from "@idot-digital/calendar-api";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid2,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import React from "react";
import DailyStatementServer from "../Server/DailyStatement/DailyStatementServer";
import { areOnSameDay, stringifyPrice } from "../../Functions/functions";
import { isSameDay } from "date-fns";
import PriceInput from "../Generic/PriceInput";
import { Styles } from "../../Types";
import { useServer } from "../Server/ServerContext";
import { DailyStatementInputData } from "../Server/DailyStatement/DailyStatementTypes";
import PromiseButton from "../Loading/PromiseButton";
import { Delete } from "@mui/icons-material";
import { deepCopy, isEqual } from "../../Functions/ObjectFunctions";
import OptionalTooltip from "../Generic/OptionalTooltip";
import AppointmentServer from "../Server/Appointments/AppointmentServer";
import EmployeeServer from "../Server/Employees/EmployeeServer";
import AppointmentFunctions from "../Server/Appointments/AppointmentFunctions";
import { Arrays } from "@idot-digital/generic-helpers";
import { useNavigate } from "react-router-dom";

const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    gap: 4,
  },
} satisfies Styles;

const getEmptyInputData = () => ({
  sourcing: 0,
  withdrawal: {
    cash: 0,
    private: 0,
  },
  tip: {
    card: 0,
    cash: 0,
  },
});

export default function DailyStatementsRoot() {
  const navigate = useNavigate();
  const { settings } = useServer();
  const [date, setDate] = React.useState(new Date());
  const [openMonth, setOpenMonth] = React.useState(SimpleDate.now());
  const { data: statementData, isLoading } =
    DailyStatementServer.useDataOfMonth(openMonth);

  const currentData = React.useMemo(
    () => statementData?.find((data) => isSameDay(data.statement_date, date)),
    [statementData, date]
  );
  const [editData, setEditData] =
    React.useState<Omit<DailyStatementInputData, "date">>(getEmptyInputData());
  React.useMemo(() => {
    setEditData(currentData?.data.fin ?? getEmptyInputData());
  }, [currentData]);

  const [unsavedChangesWarningOpen, setUnsavedChangesWarningOpen] =
    React.useState(false);
  const [newDateSelected, setNewDateSelected] = React.useState(new Date());

  const { data: employees } = EmployeeServer.useAll();
  const { data: appointments } = AppointmentServer.useDay(
    SimpleDate.fromDate(date)
  );
  const unpaidAppointments = React.useMemo(
    () =>
      appointments
        ?.filter((a) => !AppointmentFunctions.isPaid(a))
        .sort((a1, a2) => a1.main_employeeid - a2.main_employeeid) ?? [],
    [appointments]
  );
  const [unpaidAppointmentsWarningOpen, setUnpaidAppointmentsWarningOpen] =
    React.useState(false);
  const unpaidDismissedFor = React.useRef(new Date(0));
  React.useEffect(() => {
    if (!isSameDay(date, unpaidDismissedFor.current))
      setUnpaidAppointmentsWarningOpen(unpaidAppointments.length > 0);
  }, [unpaidAppointments]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
        gap: 2,
      }}
    >
      <Dialog open={unpaidAppointmentsWarningOpen}>
        <DialogTitle>
          Für den {date.toLocaleDateString("de-DE")} existieren noch unbezahlte
          Termine
        </DialogTitle>
        <DialogContent>
          <List>
            {unpaidAppointments.map((a) => (
              <ListItem key={a.id}>
                <ListItemText
                  primary={
                    employees?.find((e) => e.id === a.main_employeeid)
                      ?.fullName ?? "[Mitarbeiter konnte nicht gefunden werden]"
                  }
                  secondary={
                    a.durations[0]?.start.getTimeString() +
                    " - " +
                    a.durations.at(-1)?.end.getTimeString()
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => navigate("/")}
          >
            Zurück zum Kalender
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              unpaidDismissedFor.current = new Date(date);
              setUnpaidAppointmentsWarningOpen(false);
            }}
          >
            Trotzdem fortfahren
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h4" textAlign="center" alignSelf="center">
        Tagesabschluss
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 size={6}>
          <StaticDatePicker
            sx={{ float: "right" }}
            loading={isLoading}
            renderLoading={() => <CircularProgress />}
            displayStaticWrapperAs="desktop"
            value={new Date(date)}
            onChange={(newDate, context) => {
              if (!newDate) return;

              const lastCurrentData = currentData;
              if (hasInputDataChanged(editData, lastCurrentData?.data.fin)) {
                setUnsavedChangesWarningOpen(true);
                setNewDateSelected(newDate);
                // slightly change the date (in a non relevant way) to make sure that the date picker does not change its value when the selection is cancelled
                const modifiedDate = new Date(date);
                modifiedDate.setMilliseconds(
                  (date.getMilliseconds() % 1000) + 1
                );
                setDate(modifiedDate);
                context.validationError = "invalidDate";
              } else {
                setDate(newDate);
              }
            }}
            onMonthChange={(month) => setOpenMonth(SimpleDate.fromDate(month))}
            slotProps={{
              layout: {
                slotProps: {
                  toolbar: {
                    sx: {
                      outline: "1px solid red",
                    },
                  },
                },
              },
              day(props) {
                const isFuture =
                  props.day > SimpleDate.now().add(0, 0, -1).toDate();
                if (isFuture) return props;
                const data = statementData?.find((data) =>
                  areOnSameDay(
                    SimpleDate.fromDate(data.statement_date),
                    SimpleDate.fromDate(props.day)
                  )
                );
                return {
                  ...props,
                  sx: {
                    border: "1px solid transparent",
                    ...(data && {
                      borderColor: (theme) => theme.palette.success.main,
                    }),
                    ...(data &&
                      !data.pdf_url && {
                        borderColor: (theme) => theme.palette.error.main,
                      }),
                  },
                };
              },
            }}
            views={["day"]}
            openTo="day"
          />
          <Dialog
            open={unsavedChangesWarningOpen}
            onClose={() => setUnsavedChangesWarningOpen(false)}
          >
            <DialogTitle>Ungespeicherte Änderungen</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Sie haben ungespeicherte Änderungen. Möchten Sie diese
                verwerfen?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setUnsavedChangesWarningOpen(false)}
              >
                Abbrechen
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<Delete />}
                onClick={() => {
                  const newData =
                    statementData?.find((data) =>
                      isSameDay(data.statement_date, newDateSelected)
                    )?.data.fin ?? getEmptyInputData();
                  setEditData(deepCopy(newData));
                  setDate(newDateSelected);
                  setUnsavedChangesWarningOpen(false);
                }}
              >
                Verwerfen
              </Button>
            </DialogActions>
          </Dialog>
        </Grid2>
        <Grid2 size="auto">
          <Divider orientation="vertical" />
        </Grid2>
        <Grid2 size="grow">
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography mt={2} variant="body1">
                Barentnahme
              </Typography>
              <PriceInput
                variant="standard"
                value={editData.withdrawal.cash}
                onChange={(value) =>
                  setEditData({
                    ...editData,
                    withdrawal: { ...editData.withdrawal, cash: value ?? 0 },
                  })
                }
              />
              <Typography mt={2} variant="body1">
                Trinkgeld (Bar)
              </Typography>
              <PriceInput
                variant="standard"
                value={editData.tip.cash}
                onChange={(value) =>
                  setEditData({
                    ...editData,
                    tip: { ...editData.tip, cash: value ?? 0 },
                  })
                }
              />
              <Typography mt={2} variant="body1">
                Einkauf
              </Typography>
              <PriceInput
                variant="standard"
                value={editData.sourcing}
                onChange={(value) =>
                  setEditData({ ...editData, sourcing: value ?? 0 })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography mt={2} variant="body1">
                Privatentnahme
              </Typography>
              <PriceInput
                variant="standard"
                value={editData.withdrawal.private}
                onChange={(value) =>
                  setEditData({
                    ...editData,
                    withdrawal: { ...editData.withdrawal, private: value ?? 0 },
                  })
                }
              />
              <Typography mt={2} variant="body1">
                Trinkgeld (Karte)
              </Typography>
              <PriceInput
                variant="standard"
                value={editData.tip.card}
                onChange={(value) =>
                  setEditData({
                    ...editData,
                    tip: { ...editData.tip, card: value ?? 0 },
                  })
                }
              />
            </Box>
          </Box>
        </Grid2>
      </Grid2>
      <Divider sx={{ mt: -2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          mx: "auto",
          maxWidth: "800px",
        }}
      >
        <Box sx={styles.row}>
          <Typography fontWeight="bold">Abrechnung</Typography>
          <Typography>Betrag</Typography>
        </Box>
        <Divider />
        <Box sx={styles.row}>
          <Typography>Gesamtumsatz</Typography>
          <Typography textAlign="right">
            {stringifyPrice(
              (currentData?.data.daily_revenue.totalWithoutInvoice ?? 0) +
                (currentData?.data.fin.tip.card ?? 0) +
                (currentData?.data.fin.tip.cash ?? 0),
              settings.getKey("currency_sign")
            )}
          </Typography>
        </Box>
        <Divider />
        <Box sx={styles.row}>
          <Typography>Kassenstand (ursprünglich)</Typography>
          <Typography textAlign="right">
            {stringifyPrice(
              currentData?.data.cash.old ?? 0,
              settings.getKey("currency_sign")
            )}
          </Typography>
        </Box>
        <Divider />
        <Box sx={styles.row}>
          <Typography>Kassenstand (aktuell)</Typography>
          <Typography textAlign="right">
            {stringifyPrice(
              currentData?.data.cash.new ?? 0,
              settings.getKey("currency_sign")
            )}
          </Typography>
        </Box>
        <Divider />
        <Box sx={styles.row}>
          <Typography>EC-Zahlungen</Typography>
          <Typography textAlign="right">
            {stringifyPrice(
              currentData?.data.daily_revenue.card ?? 0,
              settings.getKey("currency_sign")
            )}
          </Typography>
        </Box>
        <Divider />
        <Box sx={styles.row}>
          <Typography>Rechnung</Typography>
          <Typography textAlign="right">
            {stringifyPrice(
              currentData?.data.daily_revenue.invoice ?? 0,
              settings.getKey("currency_sign")
            )}
          </Typography>
        </Box>
        <Divider />
        <Box sx={styles.row}>
          <Typography>Verkaufte Gutscheine (Bar / EC / Rechnung)</Typography>
          <Typography textAlign="right">
            {stringifyPrice(
              currentData?.data.totals.emitted_vouchers.cash ?? 0,
              settings.getKey("currency_sign")
            )}{" "}
            /{" "}
            {stringifyPrice(
              currentData?.data.totals.emitted_vouchers.card ?? 0,
              settings.getKey("currency_sign")
            )}{" "}
            /{" "}
            {stringifyPrice(
              currentData?.data.totals.emitted_vouchers.invoice ?? 0,
              settings.getKey("currency_sign")
            )}
          </Typography>
        </Box>
        <Divider />
        <Box sx={styles.row}>
          <Typography>Gutscheine</Typography>
          <Typography textAlign="right">
            {stringifyPrice(
              currentData?.data.daily_revenue.voucher ?? 0,
              settings.getKey("currency_sign")
            )}
          </Typography>
        </Box>
        <Divider />
        <Box sx={styles.row}>
          <Typography>Verkäufe (Bar / EC / Rechnung / Gutschein)</Typography>
          <Typography textAlign="right">
            {stringifyPrice(
              currentData?.data.totals.purchases.cash ?? 0,
              settings.getKey("currency_sign")
            )}{" "}
            /{" "}
            {stringifyPrice(
              currentData?.data.totals.purchases.card ?? 0,
              settings.getKey("currency_sign")
            )}{" "}
            /{" "}
            {stringifyPrice(
              currentData?.data.totals.purchases.invoice ?? 0,
              settings.getKey("currency_sign")
            )}{" "}
            /{" "}
            {stringifyPrice(
              currentData?.data.totals.purchases.voucher ?? 0,
              settings.getKey("currency_sign")
            )}
          </Typography>
        </Box>
      </Box>
      <Box
        display="grid"
        justifyContent="center"
        gridTemplateColumns="repeat(3, 1fr)"
        gap={2}
        maxWidth="800px"
        mx="auto"
      >
        <OptionalTooltip
          title={
            !currentData?.pdf_url
              ? `Für den ${date.toLocaleDateString("de-DE")} wurde noch kein Tagesabschluss erstellt`
              : undefined
          }
        >
          <span>
            <PromiseButton
              variant="outlined"
              color="primary"
              fullWidth
              disabled={!currentData?.pdf_url}
              onClick={() =>
                currentData?.pdf_url &&
                DailyStatementServer.openPDF(currentData.pdf_url)
              }
            >
              PDF anzeigen
            </PromiseButton>
          </span>
        </OptionalTooltip>
        <PromiseButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={async () => {
            await DailyStatementServer.setData({
              ...editData,
              date,
            });
          }}
        >
          Speichern
        </PromiseButton>
        <PromiseButton
          variant="contained"
          color="primary"
          onClick={() => DailyStatementServer.createPDF(date)}
        >
          PDF {currentData?.pdf_url ? "aktualisieren" : "erstellen"}
        </PromiseButton>
      </Box>
    </Box>
  );
}

function hasInputDataChanged(
  currentData: Omit<DailyStatementInputData, "date"> | undefined,
  editData: Omit<DailyStatementInputData, "date"> | undefined
) {
  return (
    (currentData?.sourcing ?? 0) !== (editData?.sourcing ?? 0) ||
    (currentData?.withdrawal.cash ?? 0) !== (editData?.withdrawal.cash ?? 0) ||
    (currentData?.withdrawal.private ?? 0) !==
      (editData?.withdrawal.private ?? 0) ||
    (currentData?.tip.card ?? 0) !== (editData?.tip.card ?? 0) ||
    (currentData?.tip.cash ?? 0) !== (editData?.tip.cash ?? 0)
  );
}
